import { Language, Locale } from "./util/locale";
import {
	AuditRoute,
	CreateAuditRoute,
	CreateAuditRouteBaseFarm,
	CreateAuditRouteBaseFarmAndType,
	FlockRoute,
	RouteWithId,
} from "./interfaces/routes";
import { dateToDateString } from "./util/date";
import { ChartSerie } from "./interfaces/api/Graph";
import { useMemo } from "react";
import { matchPath } from "react-router";
import { User } from "./interfaces/api/User";
import { ControlVisitAttachmentType } from "./interfaces/api/ControlVisit";
import { AuditType } from "./interfaces/api/Audit";

export enum StaticRoutes {
	FrontPage = "/",
	LaboratoryFrontPage = "/laboratory",
	Login = "/login",
	Logout = "/logout",
	EditUserProfile = "/user/profile",
	ChangePassword = "/user/changepassword",
	NewFarm = "/farms/new",
	Authorization = "/authorization",
	ReportProductionData = "/report",
	ReportSamplesResults = "/report/samplesResults",
	CustomerControlVisitList = "/controlVisits",
	CustomerInfo = "/customer",
	SampleOrder = "/sampleOrder",
	SampleListPage = "/sampleList",
	CustomerDocuments = "/documents",
	Offline = "/offline",
	AuditList = "/audit/list",
}

export enum MvcSiteRoutes {
	FrontPage = "/admin",
	FarmDocument = "/Tiladokumentit",
	LaboratoryFrontPage = "/laboratory",
	RevertImpersonation = "/user/revertImpersonation",
}

export const DynamicRoutes = {
	FrontPageWithSampleOrderSuccess: {
		route: "/",
		get: (orderSaved: boolean) => `/?orderSaved=${orderSaved}`,
	},
	Farm: {
		route: "/farm/:id",
		get: (id: RouteWithId["id"], orderSaved?: boolean) =>
			orderSaved ? `/farm/${id}?orderSaved=${orderSaved}` : `/farm/${id}`,
	},
	EditFarm: {
		route: "/farm/:id/edit",
		get: (id: RouteWithId["id"]) => `/farm/${id}/edit`,
	},
	Flock: {
		route: "/farm/:farmId/flock/:flockId",
		get: (farmId: FlockRoute["farmId"], flockId: FlockRoute["flockId"]) =>
			`/farm/${farmId}/flock/${flockId}`,
		endFlockDocuments: {
			route: "/farm/:farmId/flock/:flockId/endDocuments",
			get: (
				farmId: FlockRoute["farmId"],
				flockId: FlockRoute["flockId"]
			) => `/farm/${farmId}/flock/${flockId}/endDocuments`,
		},
	},
	FlockBasicInfoTab: {
		route: "/farm/:farmId/flock/:flockId?tab=BasicInfo",
		get: (farmId: FlockRoute["farmId"], flockId: FlockRoute["flockId"]) =>
			`/farm/${farmId}/flock/${flockId}?tab=BasicInfo`,
	},
	FlockHealthEventTab: {
		route: "/farm/:farmId/flock/:flockId?tab=Health",
		get: (
			farmId: FlockRoute["farmId"],
			flockId: FlockRoute["flockId"],
			orderSaved?: boolean | undefined
		) =>
			orderSaved
				? `/farm/${farmId}/flock/${flockId}?tab=Health&orderSaved=${orderSaved}`
				: `/farm/${farmId}/flock/${flockId}?tab=Health`,
	},
	FlockProductionLogTab: {
		route: "/farm/:farmId/flock/:flockId",
		get: (farmId: string, flockId: string, date: string) =>
			`/farm/${farmId}/flock/${flockId}?date=${date}`,
	},
	Authorization: {
		route: "/authorization/:id",
		get: (id: RouteWithId["id"]) => `/authorization/${id}`,
	},
	PasswordReset: {
		request: "/user/passwordreset",
		perform: {
			route: `/user/passwordreset/:tokenId`,
			get: (tokenId: string) => `/user/passwordreset/${tokenId}`,
		},
	},
	Graph: {
		ForFlockWithFilter: {
			get: (customerId: string, flockId: string, filter: ChartSerie) =>
				`/report?customer=${customerId}&flocks=${flockId}&filters=${filter}`,
		},
		ForFlockWithFilterAndRecommendationData: {
			get: (customerId: string, flockId: string, filter: ChartSerie) =>
				`/report?customer=${customerId}&flocks=${flockId}&filters=${filter}&breedRecommendationsData=enabled`,
		},
	},
	UserRegistration: {
		edit: {
			route: "/user/registration",
			linkRoute: "/user/registration/:userId",
		},
		confirm: {
			route: "/user/registration/:userId/confirm",
			save: (userId: string) =>
				`/user/registration/${encodeURIComponent(userId)}/confirm`,
		},
	},
	EndedFlocksList: {
		route: "/farm/:id/flocks/ended",
		get: (id: RouteWithId["id"]) => `/farm/${id}/flocks/ended`,
	},
	SamplingGuide: {
		route: "/farms/:farmId/flocks/:flockId/samplingGuide",
		get: (farmId: string, flockId: string) =>
			`/farms/${farmId}/flocks/${flockId}/samplingGuide`,
	},
	SampleOrder: {
		forFlock: {
			route: "/farm/:farmId/flock/:flockId/sampleOrder",
			get: (
				farmId: FlockRoute["farmId"],
				flockId: FlockRoute["flockId"]
			) => `/farm/${farmId}/flock/${flockId}/sampleOrder`,
		},
		forFarm: {
			route: "/farm/:farmId/sampleOrder",
			get: (farmId: FlockRoute["farmId"]) =>
				`/farm/${farmId}/sampleOrder`,
		},
	},
	PackerSamples: {
		route: "/packer/:id/samples",
		get: (id: RouteWithId["id"]) => `/packer/${id}/samples`,
	},
	SlaughterHouseSamples: {
		route: "/slaughterHouse/:id/samples",
		get: (id: RouteWithId["id"]) => `/slaughterHouse/${id}/samples`,
	},
	Audit: {
		withId: {
			route: "/farms/:farmId/audits/:auditId",
			get: (farmId: string, auditId: string) =>
				`/farms/${farmId}/audits/${auditId}`,
		},
		createForFarm: {
			route: "/audit/farm/:farmId",
			get: (farmId: CreateAuditRouteBaseFarm["farmId"]) =>
				`/audit/farm/${farmId}`,
		},
		createForFarmWithType: {
			route: "/audit/farm/:farmId/type/:type",
			get: (
				type: CreateAuditRouteBaseFarmAndType["type"],
				farmId: CreateAuditRouteBaseFarmAndType["farmId"],
				auditDate: CreateAuditRouteBaseFarmAndType["auditDate"]
			) => `/audit/farm/${farmId}/type/${type}?auditDate=${auditDate}`,
		},
		createForFlockWithType: {
			route: "/audit/farm/:farmId/type/:type/flock/:flockId",
			get: (
				type: CreateAuditRoute["type"],
				farmId: CreateAuditRoute["farmId"],
				flockId: CreateAuditRoute["flockId"],
				auditDate: CreateAuditRoute["auditDate"]
			) =>
				`/audit/farm/${farmId}/type/${type}/flock/${flockId}?auditDate=${auditDate}`,
		},
		show: {
			route: "/audit/:auditId",
			get: (auditId: AuditRoute["auditId"]) => `/audit/${auditId}`,
		},
	},
};

export const DynamicMvcSiteRoutes = {
	Document: {
		download: (id: string, language: Language) =>
			`/Document/Download/${id}?language=${language}`,
	},
	UserManual: (customerId: string) => `/Kayttoohje/${customerId}`,
	DataProtectionStatement: (language: Language) =>
		`/Tietosuojaseloste?language=${language}`,
};

export const allStaticRoutes = Object.keys(StaticRoutes).map(
	(key) => (StaticRoutes as any)[key]
);

export enum ApiRoutes {
	Login = "auth/login",
	Logout = "auth/logout",
	Userinfo = "auth/userinfo",
	UserProfile = "user/profile",
	ChangePassword = "user/changepassword",
	VeterinaryDocumentTemplates = "document/veterinary-templates",
	ControlVisitReportForm = "control-visit/report-form",
	FodderLogTemplate = "api/documents/fodderLogTemplate",
	InHouseControlDescriptionTemplate = "api/documents/inHouseControlDescriptionTemplate",
	GlobalSettings = "settings/global",
}

export interface FarmFilters {
	flockSampleDateMin?: Date;
	flockSampleDateMax?: Date;
}

export const DynamicApiRoutes = {
	Announcement: (customerId?: string) =>
		`announcement/announcements/${
			customerId ? `?customerId=${customerId}` : ""
		}`,
	Resources: (locale: Locale) => `settings/resources?culture=${locale}`,
	Farm: {
		create: (customerId: string) => withCustomer("farms", customerId),
		get: (id: string, customerId: string) =>
			withCustomer(`farms/${id}`, customerId),
		getWithSamples: (
			id: string,
			customerId: string,
			filters: FarmFilters = {}
		) =>
			withParameters(withCustomer(`farms/${id}`, customerId), {
				returnSamples: true,
				...filters,
			}),
		edit: (id: string, customerId: string) =>
			withCustomer(`farms/${id}`, customerId),
		getAll: (customerId: string) => withCustomer("farms", customerId),
		delete: (id: string, customerId: string) =>
			withCustomer(`farms/${id}`, customerId),
	},
	Flock: {
		create: (farmId: string) => `farms/${farmId}/flocks`,
		getCreationInfo: (farmId: string, customerId: string) =>
			`farms/${farmId}/flocks/creationInfo?customer=${customerId}`,
		get: (flockId: string) => `farms/flocks/${flockId}`,
		getEnded: (farmId: string) => `farms/${farmId}/flocks/ended`,
		markAsEnded: (id: string, customerId: string) =>
			withCustomer(`flocks/${id}/status/ended`, customerId),
		edit: (farmId: string, flockId: string) =>
			`farms/${farmId}/flocks/${flockId}`,
		editStatus: (farmId: string, flockId: string) =>
			`farms/${farmId}/flocks/${flockId}/editStatus`,
		getSamplingGuide: (flockId: string) =>
			`farms/flocks/${flockId}/samplingGuide`,
		getCustomerBirdReport: (customerId: string) =>
			`flocks/customerBirdReport?customer=${customerId}`,
		getEndFormInfo: (id: string) => `flocks/${id}/endFlock/info`,
		getEndFormTargetProducer: (id: string, email: string) =>
			`flocks/${id}/endFlock/producer?email=${email}`,
		createEndFlockDocuments: (id: string) =>
			`flocks/${id}/endFlock/create-documents`,
	},
	ProductionLog: {
		status: (flockId: string) =>
			`bookkeeping/productionData/${flockId}/status`,
		byDate: (flockId: string, date: Date) =>
			`bookkeeping/productionData/${flockId}?date=${dateToDateString(
				date
			)}`,
		save: () => `bookkeeping/productionData`,
		getFlockProductionDataReport: (flockId: string) =>
			`bookkeeping/productionData/${flockId}/excel`,
	},
	ProductionDataAlerts: {
		get: (flockId: string) => `productionDataAlerts/${flockId}`,
		getByDate: (flockId: string, date: Date) =>
			`productionDataAlerts/${flockId}?date=${dateToDateString(date)}`,
	},
	Authorizations: {
		getUserStatus: (customerId: string, email: string) =>
			`authorizations/customer/${customerId}/user-status?email=${encodeURIComponent(
				email
			)}`,
		getForCurrentUser: (customerId: string) =>
			`authorizations/current/${customerId}`,
		getForCustomer: (customerId: string) =>
			`authorizations/customer/${customerId}`,
		create: (customerId: string) => `authorizations/customer/${customerId}`,
		edit: (authorizationId: string) => `authorizations/${authorizationId}`,
		delete: (authorizationId: string) =>
			`authorizations/${authorizationId}`,
	},
	PasswordReset: {
		request: (language: Language) => `user/passwordreset?lan=${language}`,
		get: (tokenId: string, language: Language) =>
			`user/passwordreset/${tokenId}?lan=${language}`,
		save: (tokenId: string, language: Language) =>
			`user/passwordreset/${tokenId}?lan=${language}`,
	},
	UserRegistration: {
		info: "user/registrationinfo",
		create: "user/registration",
		update: (userId: string) =>
			`user/registration/${encodeURIComponent(userId)}`,
		get: (userId: string, email: string) =>
			`user/registration/${encodeURIComponent(
				userId
			)}?email=${encodeURIComponent(email)}`,
		confirm: (userId: string, email: string) =>
			`user/registration/${encodeURIComponent(
				userId
			)}/confirm?email=${encodeURIComponent(email)}`,
	},
	Customer: {
		get: (customerId: string) => `customer?customer=${customerId}`,
		getAvailablePackers: (customerId: string) =>
			`customer/availablePackers?customer=${customerId}`,
		getAvailableSlaughterHouses: (customerId: string) =>
			`customer/availableSlaughterHouses?customer=${customerId}`,
		getAvailableLocationCodes: (customerId: string) =>
			`customer/availableLocationCodes?customer=${customerId}`,
		getAvailableFarmsForProducerReport: (customerId: string) =>
			`customer/availableFarms?customer=${customerId}`,
		edit: (customerId: string) => `customer?customer=${customerId}`,
	},
	Samples: {
		getSamplesByFlock: (flockId: string) => `sample/flock/${flockId}`,
		getSamplesByFarm: (farmId: string) => `sample/farm/${farmId}`,
		getSamplesByCustomer: (customerId: string) =>
			`sample/customer/${customerId}`,
		getSamplesByCustomerForProducerReport: (customerId: string) =>
			`sample/customer/${customerId}/resultsReport`,
		getSampleOrderOptions: (
			customerId: string,
			farmId?: string,
			flockId?: string
		) =>
			`sampleOrder/options?customer=${customerId}${
				flockId
					? `&flockId=${flockId}`
					: farmId
					? `&farmId=${farmId}`
					: ""
			}`,
		createSampleOrder: (customerId: string) =>
			`sampleOrder?customer=${customerId}`,
		getSamplesForPacker: (packerId: string) => `sample/packer/${packerId}`,
		getSamplesForSlaughterHouse: (slaughterHouseId: string) =>
			`sample/slaughterHouse/${slaughterHouseId}`,
	},
	SampleReminderBan: {
		ensure: (farmId: string, userId: string) =>
			`sample-reminder-ban/farm/${farmId}/user/${userId}`,
		delete: (farmId: string, userId: string) =>
			`sample-reminder-ban/farm/${farmId}/user/${userId}`,
	},
	Graph: {
		getFlocks: (customerId: string) =>
			`bookkeepingreport/flockOptions/${customerId}`,
		get: (flockId: string, filters: ChartSerie[] | undefined) =>
			`bookkeepingreport?flockId=${flockId}${filters
				?.map((filter) => `&filters=${filter}`)
				.join("")}`,
		getReferenceData: (
			flockId: string,
			filters: ChartSerie[] | undefined
		) =>
			`bookkeepingreport/referenceData?flockId=${flockId}${filters
				?.map((filter) => `&filters=${filter}`)
				.join("")}`,
		getNationalData: (flockId: string, filters: ChartSerie[] | undefined) =>
			`bookkeepingreport/nationalData?flockId=${flockId}${filters
				?.map((filter) => `&filters=${filter}`)
				.join("")}`,
	},
	HealthEvent: {
		create: "health-event",
		get: (flockId: string) => `health-event/flock/${flockId}`,
		delete: (healthEventId: string) => `health-event/${healthEventId}`,
		options: "health-event/options",
	},
	PackerBatch: {
		getPackerBatchForDate: (flockId: string, date: Date) =>
			`flocks/${flockId}/packer-batch?deliveryDate=${dateToDateString(
				date
			)}`,
		getPackerBatchForWeek: (
			flockId: string,
			deliveryDateMin: Date,
			deliveryDateMax: Date
		) =>
			`flocks/${flockId}/packer-batch?deliveryDateMin=${dateToDateString(
				deliveryDateMin
			)}&deliveryDateMax=${dateToDateString(deliveryDateMax)}`,
	},
	ControlVisit: {
		create: (customerId: string) => `control-visit/customer/${customerId}`,
		getByCustomer: (customerId: string) =>
			`control-visit/customer/${customerId}`,
		getByFarm: (farmId: string) => `control-visit/farm/${farmId}`,
		delete: (id: string) => `control-visit/${id}`,
		downloadAttachment: (id: string, type: ControlVisitAttachmentType) =>
			`/api/control-visit/attachment/${id}/${type}`,
	},
	CustomerDocuments: {
		getAll: (customerId: string) => withCustomer("documents", customerId),
		create: "documents",
		download: (documentId: string) =>
			`api/documents/download/${documentId}`,
		delete: (documentId: string) => `documents/${documentId}`,
	},
	Packer: {
		getCustomers: (packerId: string) => `packer/${packerId}/customers`,
		getOverdueSamples: (packerId: string) =>
			`packer/${packerId}/overdueSamples`,
	},
	SlaughterHouse: {
		getCustomers: (slaughterHouseId: string) =>
			`slaughterHouse/${slaughterHouseId}/customers`,
	},
	Audit: {
		new: (type: AuditType, farmId: string) =>
			`audit/record/new?auditType=${type}&farmId=${farmId}`,
		create: (customerId: string) =>
			withCustomer("audit/record", customerId),
		get: (auditId: string) => `audit/record/${auditId}`,
		listForCustomer: (customerId: string) =>
			withParameters("audit/record/list", { customerId }),
		listForPacker: (packerId: string) =>
			withParameters("audit/record/list", { packerId }),
		listForVeterinary: (veterinarianId: string) =>
			withParameters("audit/record/list", { veterinarianId }),
		getAuditsForFarm: (customerId: string, farmId: string) =>
			withParameters("audit/record/list", { customerId, farmId }),
		getAuditsForFlock: (customerId: string, flockId: string) =>
			withParameters("audit/record/list", { customerId, flockId }),
		getAttachment: (attachmentId: string) =>
			`/api/audit/record/attachment/${attachmentId}`,
	},
};

function splitToPathAndQuery(route: string): { path: string; query: string } {
	const pos = route.indexOf("?");
	const path = pos === -1 ? route : route.substring(0, pos);
	const query = pos === -1 ? "" : route.substring(pos + 1);
	return { path, query };
}

export const customerParameterName = "customer";
export function withCustomer(route: string, customerId: string) {
	const { path, query } = splitToPathAndQuery(route);

	const params = new URLSearchParams(query);
	params.set(customerParameterName, customerId);

	return path + "?" + params.toString();
}

export function withParameters(route: string, params: object) {
	const { path, query } = splitToPathAndQuery(route);

	const searchParams = new URLSearchParams(query);
	for (const [key, value] of Object.entries(params)) {
		if (value instanceof Date) {
			searchParams.set(key, dateToDateString(value));
		} else if (value !== undefined && value !== null) {
			searchParams.set(key, value.toString());
		}
	}

	return path + "?" + searchParams.toString();
}

const returnUrlParameterName = "returnUrl";
export const returnUrlParameterRegex = new RegExp(returnUrlParameterName, "i");
export function withReturnUrl(route: string, returnUrl: string) {
	return returnUrl === "/"
		? route
		: `${route}?${returnUrlParameterName}=${returnUrl}`;
}

export function getKnownRoutes() {
	const routes = Object.values(StaticRoutes).map((v) => v as string);
	Object.values(DynamicRoutes).forEach((routeObj) => {
		Object.values(routeObj).forEach((value) => {
			if (typeof value === "string") {
				routes.push(value as string);
			} else if (typeof value !== typeof "function") {
				Object.values(value as any).forEach((childValue) => {
					if (typeof childValue === "string") {
						routes.push(childValue as string);
					}
				});
			}
		});
	});
	return routes;
}

export function isKnownRouteForUser(
	knownRoutes: string[],
	user?: User,
	redirectUrl?: string | null
): boolean {
	return (
		(user &&
			redirectUrl &&
			knownRoutes.some((r) => matchPath(r, redirectUrl))) ||
		false
	);
}

export function useIsKnownFrontRouteForUser(
	user?: User,
	redirectUrl?: string | null
) {
	const frontRoutes = useMemo(() => {
		return getKnownRoutes();
	}, []);

	return isKnownRouteForUser(frontRoutes, user, redirectUrl);
}

export const farmParameterName = "farm";
