export class KanavaApiException extends Error {
	readonly errorMessage: string;
	readonly statusCode?: number;

	public constructor(message: string, statusCode: number | undefined) {
		super(message);
		Object.setPrototypeOf(this, KanavaApiException.prototype);
		this.errorMessage = message;
		this.statusCode = statusCode;
	}
}

export class KanavaApiValidationException<
	TErrors = unknown
> extends KanavaApiException {
	readonly errors: Record<keyof TErrors, readonly string[] | undefined>;

	public constructor(
		message: string,
		statusCode: number | undefined,
		errors: Record<keyof TErrors, readonly string[]>
	) {
		super(message, statusCode);
		Object.setPrototypeOf(this, KanavaApiValidationException.prototype);
		this.errors = errors;
	}
}

export function isKanavaApiValidationException<TErrors>(
	input: any
): input is KanavaApiValidationException<TErrors> {
	return input instanceof KanavaApiValidationException;
}

export interface ValidationProblemResponse {
	readonly type: "https://tools.ietf.org/html/rfc7231#section-6.5.1";
	readonly title: string;
	readonly status: 400;
	readonly traceId: string;
	readonly errors: Record<string, readonly string[]>;
}

export function isValidationProblemResponse(
	input: any
): input is ValidationProblemResponse {
	const obj = input as ValidationProblemResponse;
	return (
		obj.type === "https://tools.ietf.org/html/rfc7231#section-6.5.1" &&
		obj.status === 400 &&
		typeof obj.errors === "object" &&
		Object.keys(obj.errors).length > 0
	);
}
